import { Loader } from "@googlemaps/js-api-loader";
import { Ref, ref } from "vue";
import { Service } from "./service";

const apiKey = import.meta.env.VITE_GOOGLE_MAPS_BROWSER_API_KEY;
const googleAdsClientPubId = import.meta.env.VITE_GOOGLE_ADS_CLIENT_PUBLISHER_ID;
const googleAdsSlot = import.meta.env.VITE_GOOGLE_ADS_SLOT;
const showLiveAds = import.meta.env.VITE_SHOW_LIVE_GOOGLE_ADS;

export interface GoogleService extends Service {
  loadGoogle(): Promise<void>;
  getPlacesService(attrContainer: HTMLDivElement | google.maps.Map): google.maps.places.PlacesService;

  googleRef: Ref<typeof google | undefined>;
  showLiveAds: boolean;
  googleAdsClientPubId: string;
  googleAdsSlot: string;
  googleAdsScriptSrcUrl: string;
}


export function createGoogleService() {
  const loader = new Loader({
    apiKey,
    version: "beta",
    libraries: ["places", "geometry"]
  });
  
  return new GoogleServiceImpl(
    loader, 
    !!showLiveAds,
    googleAdsClientPubId,
    googleAdsSlot,
    `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${googleAdsClientPubId}`
  );
}

export class GoogleServiceImpl implements GoogleService {
  public readonly isLoading = ref(true);
  public readonly googleRef = ref<typeof google>();
  public readonly lastErrorMessage = ref<string | undefined>();
  public readonly showLiveAds;
  public readonly googleAdsClientPubId;
  public readonly googleAdsSlot;
  public readonly googleAdsScriptSrcUrl;

  private loader: Loader;
  
  constructor(
    loader: Loader, 
    showLiveAds: boolean,
    googleAdsClientPubId: string,
    googleAdsSlot: string,
    googleAdsScriptSrcUrl: string

  ) {
    this.loader = loader;
    this.showLiveAds = showLiveAds;
    this.googleAdsClientPubId = googleAdsClientPubId;
    this.googleAdsSlot = googleAdsSlot;
    this.googleAdsScriptSrcUrl = googleAdsScriptSrcUrl;
  }

  public async loadGoogle(): Promise<void> {
    const loader = this.loader;
    const isLoading = this.isLoading;
    const lastErrorMessage = this.lastErrorMessage;
    const googleRef = this.googleRef;

    try {
      isLoading.value = true;
      googleRef.value = await loader.load();
    } catch (error /* The loader doesn't actually throw an 'Error', per se... */) {
      lastErrorMessage.value = "Something went wrong loading Google Maps services. Please try again later.";
    } finally {
      isLoading.value = false;
    }

  }

  public getPlacesService(attrContainer: HTMLDivElement | google.maps.Map) {
    return new this.googleRef.value!.maps.places.PlacesService(attrContainer);      
  }
}
