/**
 * Checks for and rejects some of the most common errors users make when entering email addresses.
 * This is a simple heuristic, and does NOT formally validate an email address according to the RFCs.
 * 
 * @param emailAddress The email address to check
 * 
 * @returns true if emailAddress is possibly valid, false if emailAddress is invalid specifically due to a missing '@' symbol or '.' symbol.
 */
export function possiblyValidEmailAddress(emailAddress: string | null | undefined) {
  return (emailAddress ?? "").length > 0
    && String(emailAddress)
    .toLowerCase()
    .match(/^\S+@\S+\.\S+$/) != null;
};

/**
 * 
 */
const validUrlTemplateRegExp = /^([^\x00-\x20\x7f"'%<>\\^`{|}]|%[0-9A-Fa-f]{2}|{[+#./;?&=,!@|]?((\w|%[0-9A-Fa-f]{2})(\.?(\w|%[0-9A-Fa-f]{2}))*(:[1-9]\d{0,3}|\*)?)(,((\w|%[0-9A-Fa-f]{2})(\.?(\w|%[0-9A-Fa-f]{2}))*(:[1-9]\d{0,3}|\*)?))*})*$/;

/**
 * Checks for and rejects strings that are empty, not defined, or otherwise do not conform to the ABNF rules in Section 2 of RFC 6570
 * 
 * @param urlTemplate The urlTemplate to check
 * @returns true if urlTemplate conforms to the ABNF rules in Section 2 of RFC 6570 
 */
export function validUrlTemplate(urlTemplate: string | null | undefined) {
  return (urlTemplate ?? "").length > 0
    && validUrlTemplateRegExp.test(urlTemplate!)
}

