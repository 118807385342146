<template>
  <div class="d-flex flex-column min-vh-100 bg-light">
    <ERTPHeader :urlPathname="pageContext.urlPathname"/>
    <main class="d-flex flex-column flex-fill m-3 bg-light">
      <div class="d-flex flex-column flex-fill container-md col-lg-9 col-xl-8 col-xxl-7 bg-light">
        <slot />
      </div>
    </main>

    <ERTPSignInModal v-model:show="showSignInModal" />
    <ERTPSignUpModal v-model:show="showSignUpModal" />

    <ERTPFooter/>
  </div>

  <ERTPCookieConsentBanner/>
</template>
 
<script setup lang="ts">
import { clientOnly } from 'vike-vue/clientOnly'
import { usePageContext } from 'vike-vue/usePageContext'

import ERTPHeader from '../components/ERTPHeader.vue';
import ERTPFooter from '../components/ERTPFooter.vue';
import ERTPSignInModal from '../components/modals/ERTPSignInModal.vue';
import ERTPSignUpModal from '../components/modals/ERTPSignUpModal.vue';

const ERTPCookieConsentBanner = clientOnly(() => import('../components/ERTPCookieConsentBanner.vue'));


import { useAuthModals } from '../composables/authModals';

const pageContext = usePageContext()
const { showSignInModal, showSignUpModal } = useAuthModals();

</script>


<style>
@import '../scss/styles.scss';
@import '@vuepic/vue-datepicker/dist/main.css';

.ertp-icon-button {
  width: 37px;
  min-width: 37px;
}

.vuecal__event {background-color: rgba(66, 128, 195, 0.35);}
.vuecal__menu, .vuecal__cell-events-count {background-color: #4280c359;}
.vuecal__title-bar {background-color: #4280c3cc;}
.vuecal__cell--today, .vuecal__cell--current {background-color: rgba(41, 170, 225, .1);}
.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {background-color: rgba(41, 170, 225, .3);}
.vuecal__cell--selected:before {border-color: rgba(41, 170, 225, .4);}
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {background-color: rgba(195, 255, 225, 0.5);}
.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {background-color: rgba(136, 236, 191, 0.25);}
</style>
